<template>
    <div class="fullscreen" v-show="modals.fullscreen" :class="{mobile: isMobile}">
        <header :class="{mobile: isMobile}" class="fs">
            <!-- <div class="empty" v-show="isMobile"></div> -->
            <div>
                <BaseToggleIconButton v-if="isMobile" class="burger" :button-style="'secondary'" @update="showMenu">
                    <template #init-icon>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M3 18.5V16.5H21V18.5H3ZM3 13.5V11.5H21V13.5H3ZM3 8.5V6.5H21V8.5H3Z" fill="#BFC6DC"/>
                        </svg>
                    </template>
                    <template #active-icon>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
                            <path d="M3 18.5V16.5H21V18.5H3ZM3 13.5V11.5H21V13.5H3ZM3 8.5V6.5H21V8.5H3Z" fill="#AFC6FF"/>
                        </svg>
                    </template>
                </BaseToggleIconButton>
                <BaseLogo class="header-logo" :src="logoComputed" :height="logo.height">
                    <template v-if="!logoComputed" #logo-placeholder>
                        <span class="brand">{{ tokens.header.cfd }}</span>
                        {{ tokens.header.platform }}
                    </template>
                </BaseLogo>
            </div>
            <div class="right">
                <div class="head" :class="{mobile: isMobile}">
                    <BaseAutotraderButton
                        @click.native="showAutoTraderModal"
                        v-tooltip="'Auto Trader'"
                        class="autotrader"
                    />
                    <div class="settings">
                        <BaseToggleIconButton :button-style="'secondary'" @update="showMenuSettings">
                            <template #init-icon>
                                <span class="material-symbols-outlined">settings</span>
                            </template>
                            <template #active-icon>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M9.25001 22L8.85001 18.8C8.63335 18.7167 8.42918 18.6167 8.23751 18.5C8.04585 18.3833 7.85835 18.2583 7.67501 18.125L4.70001 19.375L1.95001 14.625L4.52501 12.675C4.50835 12.5583 4.50001 12.4458 4.50001 12.3375V11.6625C4.50001 11.5542 4.50835 11.4417 4.52501 11.325L1.95001 9.375L4.70001 4.625L7.67501 5.875C7.85835 5.74167 8.05001 5.61667 8.25001 5.5C8.45001 5.38333 8.65001 5.28333 8.85001 5.2L9.25001 2H14.75L15.15 5.2C15.3667 5.28333 15.5708 5.38333 15.7625 5.5C15.9542 5.61667 16.1417 5.74167 16.325 5.875L19.3 4.625L22.05 9.375L19.475 11.325C19.4917 11.4417 19.5 11.5542 19.5 11.6625V12.3375C19.5 12.4458 19.4833 12.5583 19.45 12.675L22.025 14.625L19.275 19.375L16.325 18.125C16.1417 18.2583 15.95 18.3833 15.75 18.5C15.55 18.6167 15.35 18.7167 15.15 18.8L14.75 22H9.25001ZM12.05 15.5C13.0167 15.5 13.8417 15.1583 14.525 14.475C15.2083 13.7917 15.55 12.9667 15.55 12C15.55 11.0333 15.2083 10.2083 14.525 9.525C13.8417 8.84167 13.0167 8.5 12.05 8.5C11.0667 8.5 10.2375 8.84167 9.56251 9.525C8.88751 10.2083 8.55001 11.0333 8.55001 12C8.55001 12.9667 8.88751 13.7917 9.56251 14.475C10.2375 15.1583 11.0667 15.5 12.05 15.5Z" fill="#E3E2E9"/>
                                </svg>
                            </template>
                        </BaseToggleIconButton>
                    </div>
                    <BaseStartTradingButton @click.native="close"/>
                </div>
            </div>
        </header>

        <div class="menu" :class="{hide: isMobile}">
            <HamburgerMenu/>
        </div>
        <main :class="{dashboard: modalFullscreen.page === 'dashboard' && !aspinDesign, 'aspin-design': aspinDesign }" class="fs" ref="fullscreen">
            <DashboardPage class="cabinet-item" :class="{dashboard: !aspinDesign, mobile: isMobile}" v-if="modalFullscreen.page==='dashboard'"/>
            <!-- if to rebuild chart every time (fixes sizing) -->
            <PersonalPage class="cabinet-item" v-show="modalFullscreen.page==='personal'"/>
            <DepositPage class="cabinet-item" v-show="modalFullscreen.page==='deposit'"/>
            <WithdrawalPage class="cabinet-item" v-show="modalFullscreen.page==='withdrawal'"/>
            <VerificationPage class="cabinet-item" v-show="modalFullscreen.page==='verification'"/>
            <AccountsPage class="cabinet-item" v-show="modalFullscreen.page==='accounts'"/>
            <Support :class="{mobile: isMobile}" class="cabinet-item support" v-show="modalFullscreen.page==='support'"/>
            <Savings class="cabinet-item savings" v-show="modalFullscreen.page==='savings'"/>
            <SettingPage class="cabinet-item" v-show="modalFullscreen.page==='settings'"/>
            <div class="complete-verification" v-if="!verificationState">
                {{tokens.fullScreenDashboard.completeVerification}}
            </div>
        </main>
    </div>
</template>

<script>
import {mapGetters,mapMutations} from 'vuex'
import {MODAL} from '@/store/mutations.type'
import {
    LOGO,
    WEBSITE,
    TRADINGVIEW_THEME,
    ASPIN_DESIGN,
    PLATES_FOLDER,
    REST_ENDPOINT, HIDE_LOGO, WHITE_ASPIN_THEME, LOGO_REDIRECT_OFF
} from "@/common/config"
import {isMobile} from '@/common/helpers'

import HamburgerMenu from '@/new-design/not-sorted/Modals/HamburgerMenu.vue'

import DashboardPage from '@/new-design/pages/dashboard/ui/DashboardPage'
import DepositPage from '@/new-design/pages/deposit/ui/DepositPage.vue'
import WithdrawalPage from '@/new-design/pages/withdrawal/ui/WithdrawalPage.vue'
import AccountsPage from '@/new-design/pages/accounts/ui/AccountsPage'
import Support from '@/new-design/pages/live-chat/ui/LiveChatPage.vue'
import Savings from '@/new-design/not-sorted/FullscreenPages/Savings'
import {DEPOSITS_GET, LOGOUT, OTP_CREATE, WITHDRAWALS_GET} from "@/store/actions.type";
import SettingPage from "@/new-design/pages/settings/ui/SettingPage.vue";
import PersonalPage from "@/new-design/pages/personal/ui/PersonalInformationPage.vue";
import VerificationPage from "@/new-design/pages/verification/ui/VerificationPage.vue";
import BaseLogo from "@/new-design/shared/ui/Icons/BaseLogo.vue";
import BaseAutotraderButton from "@/new-design/shared/ui/buttons/BaseAutotraderButton.vue";
import BaseToggleIconButton from "@/new-design/shared/ui/buttons/BaseToggleIconButton"
import BaseStartTradingButton from "@/new-design/shared/ui/buttons/BaseStartTradingButton.vue";

export default {
    name: 'Fullscreen',
    components: {
        BaseStartTradingButton,
        BaseAutotraderButton,
        BaseLogo,
        SettingPage,
        DashboardPage,
        PersonalPage,
        DepositPage,
        WithdrawalPage,
        VerificationPage,
        AccountsPage,
        Support,
        Savings,
        HamburgerMenu,
        BaseToggleIconButton
    },
    mounted(){
        if(this.lightTheme) {
            document.body.classList.add('light-theme')
        }
    },
    data() {
        return {
            isMobile: isMobile(),
            logo: LOGO,
            website: WEBSITE,
            tradingViewTheme: TRADINGVIEW_THEME,
            aspinDesign: ASPIN_DESIGN,
            platesFolder: PLATES_FOLDER,
            apiBase: REST_ENDPOINT,
            showLang: false,
            hideLogo: HIDE_LOGO,
            whiteAspinTheme: WHITE_ASPIN_THEME,
            logoRedirectOff: LOGO_REDIRECT_OFF
        }
    },
    methods: {
        ...mapMutations(["setLightTheme",'setThemeColors']),
        close() {
            this.$store.commit(MODAL, {
                fullscreen: false
            })

            setTimeout(() => {
                this.$store.commit(MODAL, {
                    hamburgerMenu: false
                })
            }, 0);
        },
        logout() {
            this.$store.dispatch(LOGOUT)
        },
        showMenu() {
            let state = this.modals.hamburgerMenu
            this.$store.commit(MODAL, {
                hamburgerMenu: !state
            })
        },
        showAutoTraderModal() {
            this.$store.commit(MODAL, {
                autoTrader: true
            })
        },
        showMenuSettings(state) {
            this.$store.commit(MODAL, {
                menuSettings: state
            })
        },
    },
    computed: {
        ...mapGetters(['user', 'tokens', 'modals', 'modalFullscreen','activeAccount','languages','rawTokens','langActive','verificationState','staticBase','lightTheme','themeColors']),
        logoComputed() {
            return this.lightTheme ? this.logo.url.replace(/white/, 'black') : this.logo.url
        },
        plate() {
            let metal = ['beginner', 'student', 'basic', 'bronze', 'bronze_plus', 'silver', 'silver_plus', 'gold', 'platinum', 'platinum_plus', 'vip', 'top', 'diamond','islamic','business','enterprise','regular','arbitrage','basicml','shift','custom','professional','brilliant'].includes(this.activeAccount.group) ? this.activeAccount.group : 'main'
            return this.staticBase + this.platesFolder + '/' + metal + '_plate.png'
        },
    },
    watch: {
        modalFullscreen: {
            deep: true,
            handler(val) {
                switch (val.page) {
                    case 'deposit':
                        this.$store.dispatch(DEPOSITS_GET)
                        break
                    case 'withdrawal':
                        this.$store.dispatch(WITHDRAWALS_GET)
                        break
                    case 'settings':
                        if (!this.user.otp) {
                            this.$store.dispatch(OTP_CREATE)
                        }
                        break
                }
                this.$refs.fullscreen.scrollTo({ top: 0, behavior: 'smooth' })
            }
        }
    }
}
</script>

<style scoped>

.fullscreen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    background: var(--System-Surface);
    display: grid;
    grid-template-columns: max-content 1fr;
    grid-template-rows: 61px 1fr;
    overflow-y: scroll;
    align-items: stretch;
    height: 100vh;
}


.fullscreen aside {
    position: static;
}

.support{
    height: 100%;
}

header {
    grid-column: 1/-1;
    border-bottom: 1px solid rgba(144, 189, 255, 0.3);
    background: var(--System-Surface);
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 20;
    padding: 0 31px 0 32px;
}

header > div {
    display: flex;
    align-items: center;
    gap: 4px;
}

header a {
    text-decoration: none;
}

header img {
    max-height: 50px;
    max-width: 200px;
}

header span {
    font-size: 24px;
    font-weight: 800;
}

.cabinet-item {
    max-width: 768px;
    margin: 0 auto;
    padding: 24px 16px 0;
}

.cabinet-item.savings {
    max-width: 100%;
    display: block;
    padding: 0;
    margin: 0;
    height: 100%;
    overflow: hidden;
}

.support {
    max-width: 100%;
    padding: 0;
}

.mobile.support {
    padding: 60px 0 0 !important;
}

.dashboard {
    background-color: var(--System-Surface);
}

main {
    overflow: scroll;
}

main.fs {
    background-color: var(--System-Surface);
}

.head {
    display: flex;
    align-items: center;
    gap: 24px;
}

/* ================================ mobile */

header.mobile {
    position: fixed;
    width: 100%;
    height: auto;
}

.aspin-design header.mobile {
    display: grid;
    grid-template-columns: repeat(2, max-content);
    place-items: self-end;
}

.fullscreen.mobile header {
    padding: 10.5px 8px;
}

.aspin-design.fullscreen.mobile header {
    padding: 20px 15px;
    gap: 20px;
}

.fullscreen.mobile .cabinet-item {
    padding: 80px 20px;
}

.aspin-design.fullscreen.mobile .cabinet-item{
    padding: 130px 20px;
}

.fullscreen.mobile {
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-auto-rows: auto;
}

.fullscreen.mobile > * {
    grid-column: 1/2;
}

.fullscreen.mobile .dashboard {
    flex-direction: column;
    gap: 30px;
}

.support.mobile .send {
    bottom: 5%;
}

.menu.mobile {
    position: fixed;
    height: 100%;
    background: rgba(30, 33, 58, 1);
    display: none;
    z-index: 10;
    padding-top: 60px;
}

.empty {
    width: 24px;
    height: 24px;
}

.right {
    display: flex;
    align-items: center;
}

.aspin-design .right {
    display: grid;
    place-items: center;
    grid-template-columns: repeat(6, max-content);
    gap: 30px;
}

.aspin-design .head {
    margin-right: 0;
}

.lang {
    position: relative;
    cursor: pointer;
}

.lang-box {
    position: absolute;
    top: 40px;
    left: -65px;
    height: 200px;
    overflow: scroll;
    padding: 10px 30px;
    background: #1b1e29;
    opacity: 0;
    visibility: hidden;
}

.light-theme .aspin-design .lang-box {
    background: var(--aspin-color-hover);
}

.lang-box.active {
    opacity: 1;
    visibility: visible;
}

.lang-item {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    color:var(--white-to-black)
}

.flag {
    transform: scale(1.5);
}

.avatar {
    transition: color ease-in-out 0.3s;
    display: flex;
    align-items: center;
}

.avatar .circle {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: var(--background);
    transition: background-color ease-in-out 0.3s;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border: 1px solid var(--background);
}

.light-theme .avatar .circle {
    border-color: var(--border-blue)
}

.avatar .circle img {
    height: 70px;
    width: 70px;
    object-fit: cover;
}

.settings {
    cursor: pointer;
}

.aspin-design.fullscreen.mobile {
    grid-template-rows: auto;
}

.complete-verification {
    border-radius: 20px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    background-color: var(--red);
    width: 30%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--white);
    font-size: 21px;
}

@media (min-width: 1000px) {
    .aspin-design.fullscreen {
        grid-template-rows: 6vw 1fr;
    }

    .fullscreen {
        /* grid-template-rows: 4vw 1fr; */
    }

    header.fs {
        padding: 0 1.5vw;
    }

    .aspin-design header.fs {
        padding: 1vw 1.5vw;
    }

    .aspin-design .head button {
        height: 3vw;
        padding: 1vw 4.5vw;
        margin-right: 1vw;
    }

    header.aspin-design {
        padding: 0;
    }

    header.fs .trading-btn {
        padding: .7vw 2.7vw;
        font-size: 1.1vw;
        border-radius: .3vw;
        font-weight: 300;
    }

    header.fs img {
        max-width: 10vw;
        max-height: 2.6vw;
    }

    header.fs span {
        font-size: 1.3vw;
    }
}

@media (max-width: 1376px) {
}

@media (max-width: 1000px) {

    header span {
        font-size: 16px;
    }
    .complete-verification {
        font-size: 14px;
        padding: 0 5px;
    }
    .aspin-design .complete-verification {
        padding: 0 15px;
    }
}

@media (max-width: 500px) {

    header img {
        max-height: 30px;
    }
}

/*------ NEW DASH -------*/

.aspin-design.fullscreen {
    font-family: 'Graphik-regular', sans-serif;
}

.aspin-design header {
    background-color: var(--aspin-background);
    /* border: none; */
}

.aspin-design .settings {
    width: 20px;
    height: 20px;
}

.switch{
    cursor: pointer;
}

.settings {
    padding-left: 16px;
    border-left: 1px solid var(--System-Outline);
}

@media(max-width: 768px) {
    .fullscreen.mobile .cabinet-item{
        padding: 80px 16px;
    }
    .fullscreen.mobile .cabinet-item.savings{
        padding: 80px 0;
    }

    .fullscreen.mobile .cabinet-item.live-chat-page.mobile{
        padding: 80px 16px 0;
        height: 99dvh;
    }
}

</style>
