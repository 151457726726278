<template>
    <div class="search-country-select-wrapper">
        <div v-if="title" class="title body__medium">{{ title }}</div>
        <div class="search-country-select"
             :class="{active: state}"
        >
            <i class="material-symbols-outlined">arrow_drop_down</i>
            <div
                class="search-country-input body__large"
                @click="toggler"
            >
                <span>
                  {{ value && value.title ? value.title : selectedTime.title }}
                  <span v-if="!(value && value.title) && !selectedTime.title" class="body__small grey">Choose a closing time</span>
                </span>
            </div>
            <BaseMenuList v-if="state" class="ElevationLight__2">
                <template #content>
                    <BaseMenuListItem
                        v-for="(option, i) in options"
                        :key="i"
                        :tag="'li'"
                        class="option"
                        :class="{active: value === option.key}"
                        @click.native="select(option)"
                    >
                        <template #content>
                            <slot :option="option" name="option">{{ option.title }}</slot>
                        </template>
                    </BaseMenuListItem>
                </template>
            </BaseMenuList>
        </div>
    </div>
</template>

<script>
import BaseMenuList from "@/new-design/shared/ui/panels/BaseMenuList.vue"
import BaseMenuListItem from "@/new-design/shared/ui/panels/BaseMenuListItem.vue"
import toggler from "@/new-design/shared/mixins/toggler"
import {mapGetters} from "vuex";

export default {
    name: "TimeSelect",
    components: {BaseMenuList, BaseMenuListItem},
    mixins: [toggler],
    props: {
        options: {
            type: Array,
            default: () => []
        },
        title: {
            type: String,
            default: '',
        },
        value: {
            type: Object,
            default: () => {
            }
        },
    },
    data() {
        return {
            selectedTime: '',
        }
    },
    methods: {
        select(time) {
            this.selectedTime = time
            this.state = false
            this.$emit('period', time)
        }
    },
    computed: {
        ...mapGetters(['modalTimePicker'])
    },
    watch: {
        modalTimePicker: {
            deep: true,
            handler() {
                this.selectedTime = ''
            }
        }
    }
}
</script>

<style scoped>
.title {
    display: flex;
    padding-bottom: 4px;
}

.search-country-select {
    position: relative;
}

.search-country-select > i {
    position: absolute;
    top: 10px;
    right: 5px;
    z-index: 1;
    transition: transform .3s ease;
}

.search-country-select > span {
    position: absolute;
    top: 15px;
    left: 10px;
    z-index: 1;
}

.search-country-select.active i {
    transform: rotate(180deg)
}

.search-country-input {
    padding: 8px;
    min-height: 40px;
    border-radius: 4px;
    border: 1px solid var(--System-Outline);
    background: transparent;
    color: var(--System-OnSurface);
    width: 100%;
    transition: all .3s ease;
    cursor: pointer;
}

.search-country-input::placeholder {
    color: var(--System-Outline);
}

.search-country-select.active .search-country-input {
    border-color: var(--System-Primary);
}

.search-country-list {
    display: none;
    position: absolute;
    top: 44px;
    width: 100%;
    overflow-x: auto;
    color: var(--System-OnSurface);
    padding: 8px 0;
    border-radius: 4px;
    background: var(--System-Surface3);
    flex-direction: column;
    z-index: 20;
}

.search-country-select.active .search-country-list {
    display: flex;
}

.search-country-list li:hover,
li.active {
    background: var(--Statelayers-onsurface-opacity08);
}

.search-country-list li {
    padding: 8px 10px;
    cursor: pointer
}

.search-country-list li.active {
    background: var(--Statelayers-primary-opacity16);
}

.search-country-list input[type="checkbox"] {
    opacity: 0;
}

.menu-list {
    width: 100%;
}

.grey {
    color: var(--System-Outline);
}
</style>
