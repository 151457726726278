<template>
    <div class="market-item" @click="change(symbol.symbol)">
        <div class="row">
            <div class="col">
                <div class="name">
                    <div class="row asset" :class="sessionsState[symbol.symbol] ? '' : 'close' ">
                        <!--                                        <img :src="staticBase+symbol.symbol.toUpperCase()+'.png'" />-->
                        <div class="symbol"
                             :class="assetSymbol(symbol.symbol)"/>
                        <div class="full-info">
                            <span>{{ symbols[symbol.symbol] ? symbols[symbol.symbol].group : symbol.group }}</span>
                            <span class="body__small">{{ symbols[symbol.symbol] ? symbols[symbol.symbol].alias : symbol.symbol }}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="price"
                             :class="sessionsState[symbol.symbol] ? (symbol.quote ? symbol.quote.dirBid : '') : 'close'">
                            {{
                                symbol.quote && symbol.quote.bid ? symbol.quote.bid.toFixed(symbol.precision) : '-'
                            }}
                        </div>
                        <div class="smaller">
                            <div class="pct"
                                 :class="{
                                    red: symbol.quote && symbol.quote.changePct < 0,
                                    green: symbol.quote && 0 < symbol.quote.changePct,
                                    hide: !sessionsState[symbol.symbol]
                                 }">
                                {{
                                    (symbol.quote && symbol.quote.changePct > 0 ? '+' : '') + (symbol.quote && symbol.quote.changePct ? symbol.quote.changePct : 0).toFixed(2) + '%'
                                }}
                            </div>
                            <div :class="{hide: sessionsState[symbol.symbol]}" class="pct close">
                                Closed
                            </div>
                        </div>
                        <BaseIconButton @click.native.stop="add(symbol.symbol)">
                            <template #icon>
                                <i class="material-icons add">add</i>
                            </template>
                        </BaseIconButton>
                        <BaseInfoIcon @click.native.stop="info(symbol.symbol)"/>
                    </div>
                </div>
            </div>
        </div>
        <svg
            @click.stop="toggleFavorite(symbol)"
            class="favorites"
            :class="{active: symbolsFavorite.some(fav => fav.symbol === symbol.symbol)}"
            xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18"
            fill="none"
        >
            <path
                d="M0 18V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H12C12.55 0 13.0208 0.195833 13.4125 0.5875C13.8042 0.979167 14 1.45 14 2V18L7 15L0 18Z"
                fill="#E3E2E9"/>
        </svg>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {SYMBOL_FAVORITE} from "@/store/mutations.type";
import BaseIconButton from "@/new-design/shared/ui/buttons/BaseIconButton.vue";
import BaseInfoIcon from "@/new-design/shared/ui/Icons/BaseInfoIcon.vue";

export default {
    name: "MarketItem",
    components: {BaseInfoIcon, BaseIconButton},
    props: {
        symbol: {
            type: Object,
            default: () => {
            }
        }
    },
    methods: {
        add(symbol) {
            this.$emit('add', symbol)
        },
        info(symbol) {
            this.$emit('info', symbol)
        },
        change(symbol) {
            this.$emit('change', symbol)
        },
        assetSymbol(symbol) {
            if (this.brand === 'cryptoalgoritm') {
                switch (symbol) {
                    case 'SUGAR':
                        return 'symbol-CAPITALSUGAR'
                    case 'DIS':
                        return 'symbol-CAPITALSUGAR'
                    default:
                        return 'symbol-' + symbol.toUpperCase().replace('.', '-')
                }
            } else {
                return 'symbol-' + symbol.toUpperCase().replace('.', '-')
            }
        },
        toggleFavorite(symbol) {
            this.$store.commit(SYMBOL_FAVORITE, {
                action: this.symbolsFavorite.indexOf(symbol) === -1 ? 'add' : 'remove',
                symbol
            })
        },
    },
    computed: {
        ...mapGetters(['sessionsState', 'symbols', 'symbolsFavorite']),
    }
}
</script>

<style scoped>
.market-item {
    display: flex;
    flex-direction: column;
    padding: 14px 18px;
    cursor: pointer;
    position: relative;
}

.name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.1em;
    font-weight: 400;
    color: var(--white-to-black);
}

.name img {
    height: 16px;
    width: 16px;
    margin-right: 5px;
}

.symbol {
    zoom: 0.25;
    margin-right: 25px;
}

.row {
    align-items: center;
}

.row span {
    color: var(--System-OnSurface);
}

@-moz-document url-prefix() {
    .market-item .symbol {
        -moz-transform: scale(0.25);
        -moz-transform-origin: 50% 50%;
        position: absolute;
        left: 0;
    }

    .market-item .name .row:first-child span {
        margin-left: 22px;
    }
}

.market-item .col {
    flex: 1 1 auto;
}

.market-item .smaller {
    font-size: 0.9em;
    display: flex;
    align-items: center;
    font-weight: 400;
    justify-content: space-between;
    margin-left: 10px;
}

.market-item .smaller i {
    font-size: 18px;
}

.market-item .edit {
    font-size: 1em;
    color: var(--white);
    cursor: pointer;
    margin-left: 5px;
}

.market-item:hover {
    background:var(--Statelayers-onsurface-opacity08);
}

.add,
.info {
    cursor: pointer;
    font-size: 16px;
    color: var(--font)
}

.info {
    width: 18px;
}

.pct {
    margin-right: 5px;
    color: var(--System-OnSurfaceVariant);
}

.favorites {
    position: absolute;
    left: -5px;
    top: 50%;
    transform: translateY(-50%) rotate(-90deg);
    cursor: pointer;
}

.favorites.active path {
    fill: var(--System-Primary);
}

.favorites path {
    fill: var(--Statelayers-onsurface-opacity08);
}

.price,
.row.close, .price.close, .pct.close {
    color: var(--System-OnSurface);
}

.full-info {
    display: flex;
    flex-direction: column;
}

.full-info span:first-child {
    color: var(--System-Outline);
    font-size: 10px;
}

.asset {
    display: grid;
    grid-template-columns: max-content 1fr;
}
</style>
