<template>
    <div :class="{hide: !modals.assetsInfo}" class="assets-info-modal">
        <div v-if="modals.assetsInfo">
            <BaseWidgetTitle :title="tokens.widgetMarketWatch.marketWatch" @close="close">
                <template #back>
                    <i class="material-symbols-outlined" @click="hideModal">arrow_left_alt</i>
                </template>
            </BaseWidgetTitle>

            <!-- need to clarify -->
            <!-- <div class="background">
                <img
                    :src="staticBase+`backgrounds/${symbols[modalAssetsInfo.symbol].symbol.toUpperCase()}.jpg`"
                    alt="">
            </div> -->
            <div class="container">
                <div class="menu" >
                    <div class="box">
                        <div class="state label__mediumprominent">
                            <div class="open-trade">{{ sessionState }}</div>
                        </div>
                        <div class="info-brand">
                            <span :class="assetSymbol()" class="symbol"></span>
                            <div class="title__large">{{ symbols[modalAssetsInfo.symbol].alias }}</div>
                        </div>
                    </div>
                    <div v-if="sessionStateBool">
                        <div class="trade">
                            <div class="left">
                                <div class="box-trade">
                                    <div class="body__extrasmall">{{ tokens.assetInfoModal.bid }}</div>
                                    <div class="values">{{ symbols[modalAssetsInfo.symbol].quote.bid.toFixed(symbols[modalAssetsInfo.symbol].precision) }}
                                    </div>
                                </div>
                                <div class="box-trade">
                                    <div class="body__extrasmall">{{ tokens.assetInfoModal.ask }}</div>
                                    <div class="values">{{ symbols[modalAssetsInfo.symbol].quote.ask.toFixed(symbols[modalAssetsInfo.symbol].precision) }}
                                    </div>
                                </div>
                                <div class="box-trade">
                                    <div class="body__extrasmall">{{ tokens.assetInfoModal.sessionChange }}</div>
                                    <div class="cur"
                                        :class="symbols[modalAssetsInfo.symbol].quote ? (symbols[modalAssetsInfo.symbol].quote.changePct < 0 ? 'red' : ( 0 < symbols[modalAssetsInfo.symbol].quote.changePct ? 'green' : '')) : ''"
                                    >
                                        {{ (symbols[modalAssetsInfo.symbol].quote && symbols[modalAssetsInfo.symbol].quote.changePct > 0 ? '+' : '') + (symbols[modalAssetsInfo.symbol].quote && symbols[modalAssetsInfo.symbol].quote.changePct ? symbols[modalAssetsInfo.symbol].quote.changePct : 0).toFixed(2) + '%' }}
                                    </div>
                                </div>
                            </div>
                            <div class="btns-wrapper">

                                <!-- need to clarify -->
                                <!-- <i :class="{active: symbolsFavorite.indexOf(modalAssetsInfo.symbol) !== -1}"
                                    class="material-icons fav"
                                    v-on:click.stop="toggleFavorite(modalAssetsInfo.symbol)"
                                >star</i> -->
                                <BaseButtonSmall
                                    :buttonText="tokens.assetInfoModal.tradeNow"
                                    :buttonStyle="'outlined-primary'"
                                    @click.native="tradeNow(modalAssetsInfo.symbol)"/>
                            </div>
                        </div>
                    </div>

                    <div class="back">

                        <div class="tabs">
                            <div class="tab-item label__large"
                                :class="{active: page === 'information'}"
                                @click="page = 'information'">
                                    <span>{{ tokens.assetInfoModal.information }}</span>
                            </div>
                            <div class="tab-item label__large"
                                :class="{active: page === 'trading'}"
                                @click="page = 'trading'">
                                    <span>{{ tokens.assetInfoModal.tradingConditions }}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="content">
                    <Information
                        :chart="charts"
                        :class="{hide: page !== 'information'}"
                        :symbolActive="modalAssetsInfo.symbol"
                        class="cabinet-item"/>
                    <TradingConditions
                        :class="{hide: page !== 'trading'}"
                        :symbolActive="modalAssetsInfo.symbol"
                        class="cabinet-item"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import {MODAL, SYMBOL_FAVORITE, SYMBOL_ACTIVE_UPDATE} from '@/store/mutations.type'
import {BRAND} from "@/common/config"
import Information from '@/new-design/not-sorted/AssetsInfo/Information'
import TradingConditions from '@/new-design/not-sorted/AssetsInfo/TradingConditions'
import {dateCorr} from '@/common/helpers'
import BaseWidgetTitle from "@/new-design/shared/ui/titles/BaseWidgetTitle.vue"
import BaseButtonSmall from "@/new-design/shared/ui/buttons/BaseButtonSmall.vue"

export default {
    name: 'AssetsInfoModal',
    components: {Information, TradingConditions, BaseWidgetTitle, BaseButtonSmall},
    mounted() {
        let now = Date.now()
        setTimeout(() => {
            setInterval(() => {
                this.checkSessionState()
            }, 60000)
        } , Math.ceil(now / 60000) * 60000 - now)
    },
    data() {
        return {
            newData: [],
            sessionState: '',
            sessionStateBool: false,
            page: 'information',
            brand: BRAND,
            tabActive: 'active'
        }
    },
    computed: {
        ...mapGetters(['modals', 'modalAssetsInfo', 'symbols', 'symbolsFavorite', 'charts', 'modals', 'tokens','staticBase']),
    },
    methods: {
        assetSymbol() {
            if (this.brand === 'cryptoalgoritm') {
                switch (this.symbols[this.modalAssetsInfo.symbol].symbol) {
                    case 'SUGAR':
                        return 'symbol-CAPITALSUGAR'
                    case 'DIS':
                        return 'symbol-CAPITALSUGAR'
                    default:
                        return 'symbol-' + this.symbols[this.modalAssetsInfo.symbol].symbol.toUpperCase().replace('.', '-')
                }
            } else {
                return 'symbol-' + this.symbols[this.modalAssetsInfo.symbol].symbol.toUpperCase().replace('.', '-')
            }
        },
        changeSymbol(symbol) {
            this.$store.commit(SYMBOL_ACTIVE_UPDATE, {
                symbol,
                id: this.modalAssetsInfo.chartActive
            })
            this.$store.commit(MODAL, {
                assetsInfo: false,
            })
        },
        hideModal() {
            this.$store.commit(MODAL, {
                assetsInfo: false
            })
        },
        close(){
            this.$emit('close');
            this.hideModal()
        },
        toggleFavorite(symbol) {
            this.$store.commit(SYMBOL_FAVORITE, {
                action: this.symbolsFavorite.indexOf(symbol) === -1 ? 'add' : 'remove',
                symbol
            })
        },
        checkSessionState() {
            if (!this.symbols[this.modalAssetsInfo.symbol]) {
                return
            }

            if (JSON.stringify(this.symbols[this.modalAssetsInfo.symbol].sessions) === '[[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1],[1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]]') {
                this.sessionState = this.tokens.assetInfoModal.open247
                this.sessionStateBool = true
                return
            }

            let date = dateCorr(),
                d = date.getUTCDay(),
                h = date.getUTCHours(),
                m = date.getUTCMinutes(),
                s = this.symbols[this.modalAssetsInfo.symbol].sessions[d][h],
                prev = h > 0 ? this.symbols[this.modalAssetsInfo.symbol].sessions[d][h - 1] : (d > 0 ? this.symbols[this.modalAssetsInfo.symbol].sessions[d - 1][23] : this.symbols[this.modalAssetsInfo.symbol].sessions[6][23]),
                next = h > 0 ? this.symbols[this.modalAssetsInfo.symbol].sessions[d][h + 1] : (d > 0 ? this.symbols[this.modalAssetsInfo.symbol].sessions[d + 1][23] : this.symbols[this.modalAssetsInfo.symbol].sessions[6][23])

            const glueSession = session => {
                let r = []

                for (let i = 0; i < 7; i++) {
                    r = r.concat(session[i])
                }

                return r
            }

            const getInitState = initState => {
                if (initState > 0 && initState < 1 && (initState * 60) > m) {
                    return this.tokens.assetInfoModal.opens
                } else if (initState > 0 && initState < 1 && (initState * 60) < m) {
                    return this.tokens.assetInfoModal.closes
                } else {
                    return initState ? this.tokens.assetInfoModal.closes : this.tokens.assetInfoModal.opens
                }
            }

            const dateToStr = date => {
                let now = dateCorr(),
                    ret = '',
                    dateDay = date.getDay(),
                    nowDay = now.getDay()

                if (dateDay === nowDay) {
                    ret = this.tokens.assetInfoModal.today
                } else if (dateDay - nowDay === 1 || dateDay - nowDay === -6) {
                    ret = this.tokens.assetInfoModal.tomorrow
                } else {
                    switch (dateDay) {
                        case 0:
                            ret = this.tokens.assetInfoModal.sunday
                            break
                        case 1:
                            ret = this.tokens.assetInfoModal.monday
                            break
                        case 2:
                            ret = this.tokens.assetInfoModal.tuesday
                            break
                        case 3:
                            ret = this.tokens.assetInfoModal.wednesday
                            break
                        case 4:
                            ret = this.tokens.assetInfoModal.thursday
                            break
                        case 5:
                            ret = this.tokens.assetInfoModal.friday
                            break
                        case 6:
                            ret = this.tokens.assetInfoModal.saturday
                            break
                    }
                }

                return ret + ' ' + this.tokens.assetInfoModal.at + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)
            }

            if ((s !== 1 && s !== 0 && m < s * 60) || next !== 0 && next !== 1) {
                date.setUTCHours(s === 0 ? ++h : h)
                date.setUTCMinutes(s === 0 ? next * 60 : s * 60)

                this.sessionState = ((s === 0 ? s * 60 : next * 60 < m ? !prev : !!prev) ? this.tokens.assetInfoModal.closes : this.tokens.assetInfoModal.opens) + ' ' + dateToStr(date)
                this.sessionStateBool = !!(s === 0 ? s * 60 : next * 60 < m ? !prev : !!prev)
            } else {
                let i = d * 24 + h,
                    hours = 1,
                    gluedSession = glueSession(this.symbols[this.modalAssetsInfo.symbol].sessions),
                    initState = gluedSession[i],
                    start = gluedSession[i],
                    minutesIsCalculate = false,
                    stop = start ? 0 : 1,
                    iteration = 0

                while (i < 24 * 7 && (start !== stop || start > stop) && iteration < 200) {
                    i++
                    iteration++
                    start = gluedSession[i]
                    if ([0, 1].indexOf(gluedSession[i]) === -1 && !minutesIsCalculate) {
                        hours += gluedSession[i]
                        minutesIsCalculate = true
                    } else {
                        hours += 1
                    }

                    if (i >= 24 * 7) {
                        i = 0
                        d = 0
                        h = 0
                        hours = 0
                        initState = gluedSession[i]
                        start = gluedSession[i]
                        stop = start ? 0 : 1
                    }
                }

                date.setUTCHours(h + Math.floor(hours - 1))
                date.setUTCMinutes(Math.round((hours - Math.floor(hours)) * 60))

                this.sessionState = getInitState(initState) + ' ' + dateToStr(date)
                this.sessionStateBool = !!initState
            }
        },
        tradeNow(symbol) {
            this.changeSymbol(symbol);
            this.close()
        }
    },
    watch: {
        modalAssetsInfo: {
            deep: true,
            handler() {
                this.checkSessionState()
            }
        }
    }
}
</script>

<style scoped>

.assets-info-modal {
    position: absolute;
    z-index: 5;
    inset: 0;
    background-color: var(--System-Surface);
    display: flex;
    flex-direction: column;
}

.assets-info-modal > div {
    max-height: 100%;
    display: flex;
    flex-direction: column;
}

.container {
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 60px);
}

.menu {
    color: var(--System-Outline);
}

.box {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 16px 16px;
}

.info-brand {
    color: var(--System-OnSurface);
    display: flex;
    align-items: center;
    gap: 12px;
}

.info-brand .symbol {
    zoom: 0.4;
}

.trade {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    padding: 0 16px 10px;
 }

 .left {
    display: flex;
    align-items: center;
    gap: 12px;
 }

.values {
    color: var(--System-OnSurface);
}

.back {
    border-top: 1px solid var(--System-OutlineVariant);
    padding: 12px 16px 10px;
 }

 .tabs {
    display: flex;
    align-items: flex-end;
    margin: 0 12px 12px;
    padding: 4px;
    border-radius: var(--Corner-Extra-small);
    border: 1px solid var(--System-OutlineVariant);
}

.tabs .tab-item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1 auto;
    padding: 5px 0;
    font-weight: 500;
    color: var(--System-Outline);
    cursor: pointer;
    text-transform: capitalize;
}

.tabs .tab-item.active {
    color: var(--System-Primary);
    background: var(--System-Surface5);
    border-radius: var(--Corner-Extra-small);
}

.tabs span {
    width: 100%;
    text-align: center;
    padding: 0 5px;
}

.content {
    overflow-y: auto;
}

@media (max-width: 1023px) {
    .assets-info-modal {
        background-color: var(--System-Surface1);
    }
}
</style>
