<template>
    <div class="chart-line">
        <div class="chart-line-head">
            <div class="title title__small">Trading results</div>
            <div class="time">
                <button
                    class="time-item title__small"
                    :class="{ active: timeframe === 'Week' }"
                    @click="changeTimeframe('Week', 7)"
                >
                    {{ tokens.fullScreenDashboard.week }}
                </button>
                <button
                    class="time-item title__small"
                    :class="{ active: timeframe === 'Month' }"
                    @click="changeTimeframe('Month', 30)"
                >
                    {{ tokens.fullScreenDashboard.month }}
                </button>
            </div>
        </div>
        <div style="display: grid; height: 100%">
            <!-- hack for highchart height -->
            <div class="no-data" v-if="!dashboardData.balance_chart.length">
                <div class="no-data-icon">
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                    >
                        <path
                            d="M4.00004 24C3.26671 24 2.63893 23.7389 2.11671 23.2167C1.59449 22.6944 1.33337 22.0667 1.33337 21.3333C1.33337 20.6 1.59449 19.9722 2.11671 19.45C2.63893 18.9278 3.26671 18.6667 4.00004 18.6667H4.35004C4.45004 18.6667 4.5556 18.6889 4.66671 18.7333L10.7334 12.6667C10.6889 12.5556 10.6667 12.45 10.6667 12.35V12C10.6667 11.2667 10.9278 10.6389 11.45 10.1167C11.9723 9.59444 12.6 9.33333 13.3334 9.33333C14.0667 9.33333 14.6945 9.59444 15.2167 10.1167C15.7389 10.6389 16 11.2667 16 12C16 12.0444 15.9778 12.2667 15.9334 12.6667L19.3334 16.0667C19.4445 16.0222 19.55 16 19.65 16H20.35C20.45 16 20.5556 16.0222 20.6667 16.0667L25.4 11.3333C25.3556 11.2222 25.3334 11.1167 25.3334 11.0167V10.6667C25.3334 9.93333 25.5945 9.30556 26.1167 8.78333C26.6389 8.26111 27.2667 8 28 8C28.7334 8 29.3612 8.26111 29.8834 8.78333C30.4056 9.30556 30.6667 9.93333 30.6667 10.6667C30.6667 11.4 30.4056 12.0278 29.8834 12.55C29.3612 13.0722 28.7334 13.3333 28 13.3333H27.65C27.55 13.3333 27.4445 13.3111 27.3334 13.2667L22.6 18C22.6445 18.1111 22.6667 18.2167 22.6667 18.3167V18.6667C22.6667 19.4 22.4056 20.0278 21.8834 20.55C21.3612 21.0722 20.7334 21.3333 20 21.3333C19.2667 21.3333 18.6389 21.0722 18.1167 20.55C17.5945 20.0278 17.3334 19.4 17.3334 18.6667V18.3167C17.3334 18.2167 17.3556 18.1111 17.4 18L14 14.6C13.8889 14.6444 13.7834 14.6667 13.6834 14.6667H13.3334C13.2889 14.6667 13.0667 14.6444 12.6667 14.6L6.60004 20.6667C6.64449 20.7778 6.66671 20.8833 6.66671 20.9833V21.3333C6.66671 22.0667 6.4056 22.6944 5.88337 23.2167C5.36115 23.7389 4.73337 24 4.00004 24Z"
                            fill="url(#paint0_linear_967_11532)"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_967_11532"
                                x1="-3.32754"
                                y1="15.2"
                                x2="14.4176"
                                y2="32.3606"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#4A7EFF" />
                                <stop offset="0.486792" stop-color="#61BEDA" />
                                <stop offset="1" stop-color="#404C73" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div class="no-data-title title__small">
                    No trading data available
                </div>
                <div class="no-data-desc body__small">
                    <span>Start trading to see your results here. </span>
                    <span
                        >Your performance metrics will be displayed once data is
                        available.</span
                    >
                </div>
            </div>
            <div
                ref="chartDiv"
                style="width: 100%; height: 500px; max-width: 100%"
            ></div>
        </div>
    </div>
</template>

<script>
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { mapGetters } from "vuex";
import { DASHBOARD_BALANCE } from "@/store/actions.type";
import { baseDarkTheme } from "@/new-design/app/theme/Themes/baseDarkTheme";
import { baseLightTheme } from "@/new-design/app/theme/Themes/baseLightTheme";

export default {
    name: "BaseLineChartNew",
    mounted() {
        this.initChart(this.lightTheme);
    },
    data() {
        return {
            time: 7,
            timeframe: "Week",
            root: null,
            series: null,
            chart: null,
        };
    },
    computed: {
        ...mapGetters(["tokens", "modals", "dashboardData", "lightTheme"]),
        getTimeframeData() {
            let dashboardBalance = this.dashboardData.balance_chart;
            let data = [];

            // delete dublicates
            for (let i = 0; i < dashboardBalance.length; i++) {
                let prevItemDate = data.length && data[data.length - 1].date;

                if (prevItemDate === dashboardBalance[i][0]) continue;

                data.push({
                    date: dashboardBalance[i][0],
                    value: dashboardBalance[i][1],
                });
            }

            return data;
        },
    },
    methods: {
        initChart(lightTheme) {
            const themeColors = lightTheme ? baseLightTheme : baseDarkTheme;
            const root = am5.Root.new(this.$refs.chartDiv);

            // Установка темы
            root.setThemes([am5themes_Animated.new(root)]);

            // Создание чарта
            const chart = root.container.children.push(
                am5xy.XYChart.new(root, {
                    panX: true,
                    panY: true,
                    wheelX: "panX",
                    wheelY: "zoomX",
                    pinchZoomX: true,
                    paddingLeft: 15,
                    paddingRight: 25,
                })
            );

            // Цвет бордера
            chart.plotContainer.get("background").setAll({
                stroke: am5.color(themeColors["--System-Surface5"]),
                strokeWidth: 0.5,
                strokeOpacity: 1,
            });

            // Скрытие кнопки zoomOut
            chart.zoomOutButton.set("forceHidden", true);

            // Добавление курсора
            let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
            cursor.lineX.set("forceHidden", true);
            cursor.lineY.set("forceHidden", true);

            // Добавление осей
            const xAxis = chart.xAxes.push(
                am5xy.DateAxis.new(root, {
                    baseInterval: {
                        timeUnit: "hour",
                        count: 1,
                    },
                    renderer: am5xy.AxisRendererX.new(root, {
                        minGridDistance: 80,
                    }),
                })
            );

            const yAxis = chart.yAxes.push(
                am5xy.ValueAxis.new(root, {
                    numberFormat: "'$ '#,###.00",
                    renderer: am5xy.AxisRendererY.new(root, {
                        minGridDistance: 50,
                    }),
                })
            );

            // yAxis tooltip
            // yAxis.set("tooltip", am5.Tooltip.new(root, {}));

            let xRenderer = xAxis.get("renderer");
            xRenderer.labels.template.setAll({
                paddingTop: 20,
                fontSize: 11,
                fill: am5.color(themeColors["--System-OnSurfaceVariant"]),
            });
            xRenderer.grid.template.setAll({
                stroke: am5.color(
                    themeColors["--System-Surface5"].toLowerCase()
                ),
                strokeWidth: 1,
                strokeOpacity: 1,
            });

            let yRenderer = yAxis.get("renderer");
            yRenderer.labels.template.setAll({
                paddingRight: 20,
                fontSize: 11,
                fill: am5.color(themeColors["--System-OnSurfaceVariant"]),
            });

            /* yRenderer.labels.template.setAll({
                minPosition: 0.1,
                maxPosition: 0.9,
            }); */

            yRenderer.grid.template.setAll({
                stroke: am5.color(themeColors["--System-Surface5"]),
                strokeWidth: 1,
                strokeOpacity: 1,
            });

            // Добавление серии
            let series = chart.series.push(
                am5xy.LineSeries.new(root, {
                    name: "Series",
                    valueYField: "value",
                    valueXField: "date",
                    xAxis: xAxis,
                    yAxis: yAxis,
                    fill: am5.color(themeColors["--System-Primary"]),
                    stroke: am5.color(themeColors["--System-Primary"]),
                    tooltip: am5.Tooltip.new(root, {
                        labelText: "$ {valueY}",
                    }),
                })
            );

            // Настройка Tooltip
            series.get("tooltip").get("background").setAll({
                strokeOpacity: 0,
            });

            // Толщина линии
            series.strokes.template.setAll({
                strokeWidth: 2,
            });

            // Добавления даты
            series.data.setAll(this.getTimeframeData);

            // Добавление градиента
            let gradient = am5.LinearGradient.new(root, {
                stops: [
                    {
                        offset: 0,
                        opacity: 0.18,
                        color: am5.color("rgb(91,147,230)"),
                    },
                    {
                        offset: 1,
                        opacity: 0,
                        color: am5.color("rgb(255,255,255)"),
                    },
                ],
            });

            series.fills.template.setAll({
                fill: gradient,
                fillOpacity: 1,
                visible: true,
            });

            series.fills.template.set("fillGradient", gradient);

            // Добавление Scrollbar
            chart.set(
                "scrollbarX",
                am5.Scrollbar.new(root, {
                    orientation: "horizontal",
                    marginBottom: 50,
                })
            );

            let scrollbarX = chart.get("scrollbarX");

            scrollbarX.get("background").setAll({
                fill: am5.color(themeColors["--System-Surface1"]),
            });

            let thumb = scrollbarX.thumb;
            thumb.setAll({
                fill: am5.color(themeColors["--System-Surface3"]),
            });

            thumb.states.create("hover", {}).setAll({
                fill: am5.color(themeColors["--System-Surface3"]),
            });

            thumb.states.create("down", {}).setAll({
                fill: am5.color(themeColors["--System-Surface3"]),
            });

            let startGrip = scrollbarX.startGrip.get("background");
            startGrip.setAll({
                fill: am5.color(themeColors["--System-Surface3"]),
                stroke: am5.color(themeColors["--System-Surface3"]),
                strokeWidth: 3,
            });

            scrollbarX.startGrip.get("icon").setAll({
                stroke: am5.color(themeColors["--System-OnSurfaceVariant"]),
                strokeWidth: 2,
            });

            startGrip.states.create("hover", {}).setAll({
                fill: am5.color(themeColors["--System-Surface3"]),
            });

            startGrip.states.create("down", {}).setAll({
                fill: am5.color(themeColors["--System-Surface3"]),
            });

            let endGrip = scrollbarX.endGrip.get("background");
            endGrip.setAll({
                fill: am5.color(themeColors["--System-Surface3"]),
                stroke: am5.color(themeColors["--System-Surface3"]),
                strokeWidth: 3,
            });

            scrollbarX.endGrip.get("icon").setAll({
                stroke: am5.color(themeColors["--System-OnSurfaceVariant"]),
                strokeWidth: 2,
            });

            endGrip.states.create("hover", {}).setAll({
                fill: am5.color(themeColors["--System-Surface3"]),
            });

            endGrip.states.create("down", {}).setAll({
                fill: am5.color(themeColors["--System-Surface3"]),
            });

            // Добавление точек
            /* series.bullets.push(function () {
                return am5.Bullet.new(root, {
                    sprite: am5.Circle.new(root, {
                        radius: 4,
                        fill: series.get("fill"),
                    }),
                });
            }); */

            // Анимация
            series.appear(1000);
            chart.appear(1000, 100);

            // Скрытие лого
            root._logo.dispose();

            this.setWeekOrMonthByAlreadyData();

            this.root = root;
            this.chart = chart;
            this.series = series;
        },
        changeTimeframe(timeframe, time) {
            if (this.time !== time) {
                this.time = time;
                this.timeframe = timeframe;
                this.$store.dispatch(DASHBOARD_BALANCE, this.time * 24);

                this.updateChartData();
            }
        },
        updateChartData() {
            if (this.series) {
                this.series.data.setAll(this.getTimeframeData);
            }
        },
        setWeekOrMonthByAlreadyData() {
            let dashboardBalance = this.dashboardData.balance_chart;

            if (dashboardBalance && dashboardBalance.length === 720) {
                this.timeframe = "Month";
                this.time = 30;
            }
        },
        destroyChart() {
            this.root.dispose();
        },
    },
    watch: {
        dashboardData: {
            deep: true,
            handler() {
                this.setWeekOrMonthByAlreadyData();
                this.updateChartData();
            },
        },
    },
    beforeDestroy() {
        this.destroyChart();
    },
};
</script>

<style scoped>
.chart-line {
    border-radius: 8px;
    border: 1px solid var(--System-OutlineVariant);
}

.chart-line-head {
    display: grid;
    grid-template-columns: repeat(2, auto);
    place-items: center stretch;
    place-content: space-between;
    padding: 12px 16px;
}

.chart-line .title {
    color: var(--System-OnSurfaceVariant);
}

.chart-line .time {
    display: flex;
    align-items: center;
    gap: 8px;
}

.chart-line .time-item {
    padding: 4px 10px;
    border-radius: 4px;
    color: var(--System-OnSurfaceVariant);
    background: var(--Statelayers-onsurfacevariant-opacity12);
    cursor: pointer;
}

.chart-line .time-item.active {
    color: var(--System-Primary);
    background: var(--Statelayers-primary-opacity12);
}

.no-data {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    min-height: 300px;
}

.no-data-desc {
    display: flex;
    flex-direction: column;
    text-align: center;
}
</style>
