<template>
    <div class="modal-wrapper"
         :class="{hide: !modals.timePicker}"
    >
        <div class="modal ElevationDark__3" @mousedown.stop
        >
            <div class="title title__large">Set Order Closing Time</div>
            <div class="time-wrapper">
                <div class="time-select">
                    <TimeSelect
                        :title="'Closing Time'"
                        :options="timePeriod"
                        v-model="period"
                        @period="setPeriod"
                    >
                        <template #option="{option}">
                            <div class="name">{{ option.title }}</div>
                        </template>
                    </TimeSelect>
                </div>
                <div class="time-container" :class="{custom: period && period.key === 'custom'}">
                    <div class="time-item">
                        <span class="body__medium">Date</span>
                        <DatePicker v-model="values.date"
                                    type="date"
                                    :confirm="true"
                                    :placeholder="'mm/yy/dddd'"
                                    :value-type="'timestamp'"
                        >
                        </DatePicker>
                    </div>
                    <div class="time-item">
                        <span class="body__medium">Time</span>
                        <DatePicker v-model="values.time"
                                    type="time"
                                    :confirm="true"
                                    :placeholder="'00:00:00'"
                                    :value-type="'timestamp'"
                        >
                        </DatePicker>
                    </div>
                </div>
            </div>
            <div class="actions">
                <BaseButton
                    :button-style="'text-primary'"
                    :button-text="'Cancel'"
                    @click.native="hideModal"
                />
                <BaseButton
                    :button-style="'filled-primary'"
                    :button-text="'Set Close Time'"
                    :disabled="!(period && period.key !== 'custom') && !(period && period.key === 'custom' && values.time && values.date)"
                    @click.native="combineDateAndTime"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {MODAL, MODAL_AUTOCLOSE, NOTIFICATION_ERROR} from "@/store/mutations.type"
import {isMobile} from "@/common/helpers"
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import {CFD_ORDER_MODIFY_REQUEST} from "@/store/actions.type"
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton.vue"
import TimeSelect from "@/new-design/features/Modals/ClosePosition/ui/TimeSelect.vue"

export default {
    name: "CloseTimeModal",
    components: {TimeSelect, BaseButton, DatePicker},
    data() {
        return {
            values: {
                left: 0,
                top: 0,
                orderActive: null,
                date: null,
                time: null,
                expiration: null
            },
            localValues: {
                date: null,
                time: null,
            },
            timePeriod: [
                {
                    key: '15m',
                    title: '15 minutes'
                },
                {
                    key: '30m',
                    title: '30 minutes'
                },
                {
                    key: '1h',
                    title: '1 hour'
                },
                {
                    key: 'custom',
                    title: 'Custom'
                },
            ],
            period: null
        }
    },
    methods: {
        isMobile,
        hideModal() {
            this.$store.commit(MODAL, {
                timePicker: false
            })
        },
        combineDateAndTime() {
            const currentTime = Date.now()
            if (this.period.key !== 'custom') {
                const periodMapping = {
                    '15m': 15 * 60 * 1000,
                    '30m': 30 * 60 * 1000,
                    '1h': 60 * 60 * 1000,
                }

                this.values.expiration = currentTime + periodMapping[this.period.key]

            } else {
                const dateStart = new Date(this.values.date)
                dateStart.setHours(0, 0, 0, 0)

                const timeOnly = this.values.time - new Date(this.values.time).setHours(0, 0, 0, 0)

                this.values.expiration = dateStart.getTime() + timeOnly
            }

            if (this.values.expiration <= currentTime) {
                this.$store.commit(NOTIFICATION_ERROR, 'INVALID_TIME')
                this.values.time = null
                this.values.date = null
                return
            }

            this.$store.dispatch(CFD_ORDER_MODIFY_REQUEST, {
                id: this.values.orderActive,
                account_id: this.activeAccount.id,
                expiration: this.values.expiration / 1000
            })

            this.$store.commit(MODAL, {
                timePicker: false
            })

            this.period = null
        },
        setPeriod(period) {
            this.period = period

            if (period.key === 'custom') {
                this.values.time = this.localValues.time
                this.values.date = this.localValues.date
            } else {
                this.values.time = null
                this.values.date = null
            }
        }
    },
    computed: {
        ...mapGetters(['modals', 'tokens', 'modalTimePicker', 'orders', 'activeAccount', 'symbols']),
    },
    watch: {
        modalTimePicker: {
            deep: true,
            handler(val) {
                for (let n in val) {
                    if (this.values[n] !== undefined) {
                        this.values[n] = val[n]
                    }

                    this.period = null
                    this.values.time = null
                    this.values.date = null

                    if (n === 'expiration') {
                        const date = new Date(val[n] * 1000)
                        const dateOnlyUTC = new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()))
                        this.values.date = dateOnlyUTC.getTime() || null

                        const timeOnlyUTC =
                            (date.getUTCHours() * 60 * 60 +
                                date.getUTCMinutes() * 60 +
                                date.getUTCSeconds()) * 1000
                        this.values.time = timeOnlyUTC || null

                        if (val[n]) {
                            this.period = {
                                key: 'custom',
                                title: 'Custom'
                            }

                            this.localValues.time = this.values.time
                            this.localValues.date = this.values.date
                        }
                    }
                }
            }
        },
        values: {
            deep: true,
            handler(val) {
                this.$store.commit(MODAL_AUTOCLOSE, val)
            }
        }
    }
}
</script>

<style scoped>
.modal-wrapper {
    position: absolute;
    z-index: 200;
}

.modal {
    position: fixed;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: var(--System-Surface3);
    z-index: 200;
    top: 50%;
    left: 50%;
    width: 350px;
    transform: translate(-50%, -50%);
    border-radius: var(--Corner-small);
}

.title {
    padding: 24px;
    color: var(--System-OnSurface);
}

.time-wrapper {
    padding: 0 24px 16px;
}

.time-container {
    display: grid;
    grid-template-columns: repeat(2, 150px);
    gap: 8px;
    margin-top: 16px;
    position: relative;
}

.time-container.custom::after {
    content: none;
}

.time-container .time-item {
    opacity: .38;
}

.time-container.custom .time-item {
    opacity: 1;
}

.time-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 103%;
    height: 100%;
    background-color: var(--System-OnSurface3);
}

.time-select {
    display: flex;
    flex-direction: column;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    padding: 24px;
}

.mx-datepicker {
    width: 150px !important;
}
</style>
