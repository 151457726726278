<template>
    <div class="modal-wrapper"
         :class="{hide: !modals.autoTrader}"
         @click.stop="hideModal">
        <div class="modal ElevationDark__3" @click.stop>
            <div class="close" @click="hideModal">
                <div class="close-container">
                    <span class="title title__large">Auto Trader</span>
                    <i class="material-icons">close</i>
                </div>
                <BaseButton
                    class="mobile-save"
                    :button-text="tokens.autoTrader.save"
                    :button-style="'filled-primary'"
                    :disabled="!accept"
                    @click.native="save"
                />
            </div>
            <BaseTextInfo
                class="message"
                :message="tokens.autoTrader.desc"
            />
            <div class="accept">
                <BaseCheckbox
                    :value="accept"
                    @click.native="accept = !accept"
                />
                <span class="terms body__medium">I have read and agree to the terms and conditions </span>
            </div>
            <div class="toggle">
                <span class="toggle-title body__large">Toggle to turn on AutoTrade</span>
                <BaseSwitch
                    v-model="enable"
                    @click="enable = !enable"
                />
            </div>
            <span class="risk-level-title body__medium">Select the risk level</span>
            <div class="modal-inner">
                <div class="row form-control" @click="setRiskLevel('low')">
                    <div class="left">
                        <BaseRadioButton :state="riskLevel.low"/>
                        <span class="body__large risk-title"> {{ tokens.autoTrader.riskLevelLow }}</span>
                    </div>
                    <span class="risk-info body__medium">
                        {{ tokens.autoTrader[tradersTittle] || tokens.autoTrader.perDay }}
                        {{ tradersPerDayStr['low'] }}
                    </span>
                </div>
                <div class="row form-control" @click="setRiskLevel('medium')">
                    <div class="left">
                        <BaseRadioButton :state="riskLevel.medium"/>
                        <span class="body__large risk-title">{{ tokens.autoTrader.riskLevelMedium }}</span>
                    </div>
                    <span class="risk-info body__medium">
                        {{ tokens.autoTrader[tradersTittle] || tokens.autoTrader.perDay }}
                        {{ tradersPerDayStr['medium'] }}
                    </span>
                </div>
                <div class="row form-control" @click="setRiskLevel('high')">
                    <div class="left">
                        <BaseRadioButton :state="riskLevel.high"/>
                        <span class="body__large risk-title">{{ tokens.autoTrader.riskLevelHigh }}</span>
                    </div>
                    <span class="risk-info body__medium">
                        {{ tokens.autoTrader[tradersTittle] || tokens.autoTrader.perDay }}
                        {{ tradersPerDayStr['high'] }}
                    </span>
                </div>
            </div>
            <div class="row actions">
                <BaseButton
                    :button-text="tokens.autoTrader.cancel"
                    :button-style="'text-primary'"
                    @click.native="hideModal"
                />
                <BaseButton
                    :button-text="tokens.autoTrader.save"
                    :button-style="'filled-primary'"
                    :disabled="!accept"
                    @click.native="save"
                />
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"
import {SET_AUTOTRADER_CONFIG} from "@/store/actions.type"
import {MODAL, NOTIFICATION, NOTIFICATION_ERROR} from "@/store/mutations.type"
import {TRADERS_PER_DAY, TRADERS_TITLE} from "@/common/config"
import BaseTextInfo from "@/new-design/shared/ui/panels/BaseTextInfo.vue"
import BaseCheckbox from "@/new-design/shared/ui/Inputs/BaseCheckbox.vue"
import BaseSwitch from "@/new-design/shared/ui/Inputs/BaseSwitch.vue"
import BaseRadioButton from "@/new-design/shared/ui/Inputs/BaseRadioButton.vue"
import BaseButton from "@/new-design/shared/ui/buttons/BaseButton.vue"

export default {
    name: "AutoTraderModal",
    components: {BaseButton, BaseRadioButton, BaseSwitch, BaseCheckbox, BaseTextInfo},
    mounted() {
        if (this.customTradersPerDay) {
            this.tradersPerDayStr.low = this.customTradersPerDay[0]
            this.tradersPerDayStr.medium = this.customTradersPerDay[1]
            this.tradersPerDayStr.high = this.customTradersPerDay[2]
        }
    },
    data() {
        return {
            tradersTittle: TRADERS_TITLE,
            riskLevel: {
                low: false,
                medium: false,
                high: false,
            },
            customerAgreementLinkCheckbox: false,
            customTradersPerDay: TRADERS_PER_DAY,
            activeRiskLevel: 'low',
            tradersPerDayStr: {
                low: '1-3',
                medium: '3-4',
                high: '5+'
            },
            tradersPerDayNum: 3,
            accept: false,
            enable: false
        }
    },
    methods: {
        hideModal() {
            this.$store.commit(MODAL, {
                autoTrader: false
            })
            this.setAutoTraderConfig()
        },
        deactivateRiskLevel() {
            for (const item in this.riskLevel) {
                this.riskLevel[item] = false
            }
        },
        setRiskLevel(risk) {
            this.deactivateRiskLevel()
            this.riskLevel[risk] = true
            this.activeRiskLevel = risk
        },
        save() {
            if (!this.accept) {
                this.$store.commit(NOTIFICATION_ERROR, 'Please press the `Accept` button')
                return
            }

            this.sendAutotraderConfig()
            this.enable ?
                this.$store.commit(NOTIFICATION, 'AutoTrader activated') :
                this.$store.commit(NOTIFICATION, 'AutoTrader disabled')
            this.hideModal()
        },
        sendAutotraderConfig() {
            let activeRiskLevel

            for (const item in this.riskLevel) {
                if (this.riskLevel[item]) {
                    activeRiskLevel = item
                    switch (item) {
                        case 'low':
                            this.tradersPerDayNum = 3
                            break
                        case 'medium':
                            this.tradersPerDayNum = 4
                            break
                        case 'high':
                            this.tradersPerDayNum = 30
                            break
                        default:
                            this.tradersPerDayNum = 3
                            break
                    }
                }
            }

            this.$store.dispatch(SET_AUTOTRADER_CONFIG, {
                risk_level: activeRiskLevel,
                trades_day: this.tradersPerDayNum,
                enable: this.enable
            })
        },
        setAutoTraderConfig() {
            this.deactivateRiskLevel()
            this.$set(this, 'tradersPerDayNum', this.autoTraderConfig.trades_day > 1 ? this.autoTraderConfig.trades_day : 3)
            this.$set(this.riskLevel, this.autoTraderConfig.risk_level, true)
            this.$set(this, 'activeRiskLevel', this.autoTraderConfig.risk_level)
            this.$set(this, 'enable', this.autoTraderConfig.enable === 1)
            this.$set(this, 'accept', this.autoTraderConfig.enable === 1)
        }
    },
    computed: {
        ...mapGetters(['modals', 'autoTraderConfig', 'tokens'])
    },
    watch: {
        autoTraderConfig() {
            this.setAutoTraderConfig()
        }
    }
}
</script>

<style scoped>
.modal-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal {
    width: 420px;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: var(--System-Surface3);
    border-radius: var(--Corner-small);
    z-index: 1100;
    position: relative;
}

.title {
    color: var(--System-OnSurface);
}

.close {
    padding: 16px;
    border-bottom: 1px solid var(--System-OutlineVariant);
}

.close-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mobile-save {
    display: none;
}

.close i {
    font-size: 1.8em;
}

.message {
    margin: 16px 16px 0;
}

.toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 16px;
}

.accept {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 12px;
    border-bottom: 1px solid var(--System-OutlineVariant);
}

.toggle-title {
    color: var(--System-OnSurface);
}

.risk-level-title {
    padding: 8px 16px;
    color: var(--System-OnSurfaceVariant);
}

.row {
    justify-content: space-between;
}

.row.actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    padding: 24px;
}

.form-control {
    padding: 14px 20px;
    align-items: center;
    cursor: pointer;
}

.modal-inner {
    border-bottom: 1px solid var(--System-OutlineVariant);
}

.risk-title {
    color: var(--System-OnSurface);
}

.risk-info {
    color: var(--System-OnSurfaceVariant);
}

.left {
    display: flex;
    align-items: center;
    gap: 10px;
}

.form-control:hover {
    background: var(--Statelayers-onsurfacevariant-opacity08);
}

@media (max-width: 767px) {
    .modal {
        width: 100%;
        height: 100%;
        padding: 0 10px;
        background: var(--System-Surface);
    }

    .title {
        flex-direction: column-reverse;
        align-items: start;
        gap: 16px;
    }

    .close {
        justify-content: flex-end;
    }

    .close-container {
        gap: 6px;
        flex-direction: row-reverse;
    }

    .modal-inner {
        border: none;
    }

    .row.actions {
        display: none;
    }

    .close {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .mobile-save {
        display: inline-flex;
    }
}

</style>
