<template>
    <div v-ripple="{color: 'var(--v-ripple-system-tertiary)', opacity: '.2'}" class="checkbox" @click="updateState"
         :class="[checkboxType, {'selected': localState}]">
        <i class="material-symbols-outlined">
            {{ localState ? 'check_box' : 'check_box_outline_blank' }}
        </i>
    </div>
</template>

<script>
import toggler from "@/new-design/shared/mixins/toggler"

export default {
    name: "BaseCheckbox",
    props: {
        checkboxType: {
            type: String
        },
        value: {
            type: [Boolean, Number]
        }
    },
    data() {
        return {
            localState: false
        }
    },
    mixins: [toggler],
    methods: {
        updateState() {
            this.state = !this.state
            this.localState = this.state
        }
    },
    watch: {
        value: {
            handler(val) {
                this.localState = val
            }
        }
    }
}
</script>


<style scoped>
.checkbox {
    padding: 8px;
    background: transparent;
    cursor: pointer;
    border-radius: 100%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox i {
    color: var(--System-OnSurfaceVariant);
}

.checkbox:hover {
    background: var(--Statelayers-onsurface-opacity08);
}

.checkbox.error path {
    fill: var(--System-Error);
}

.checkbox.error:hover {
    background: var(--Statelayers-error-opacity08);
}

.checkbox.selected {
    background: var(--Statelayers-tertiary-opacity08);
}

.checkbox.selected i {
    color: var(--System-Tertiary);
}
</style>
